import { Injectable } from '@angular/core';
import { HttpClient,HttpHeaders, HttpParams } from '@angular/common/http';
import { OccEndpointsService } from '@spartacus/core';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ContentService {
  
  constructor(private http: HttpClient, 
    private occ:OccEndpointsService) { 
  }
  public togglemabmenu:boolean=false;

  getnavigation(){
    const url = this.occ.buildUrl("/cms/components/AboutLink"); 
    return this.http.get(url);
  } 
  getimage(){
    const url = this.occ.buildUrl("/cms/components/SiteLogoComponent"); 
     return this.http.get(url);
  } 
  getimage1(){
    const url = this.occ.buildUrl("/cms/components/SeqirusSiteLogoComponent"); 
     return this.http.get(url);
  }
  getNavigation(componentName:string){
    const url = this.occ.buildUrl("/cms/components/"+componentName); 
     return this.http.get(url);
  }
  getAccessResources(){
    const url = this.occ.buildUrl("/cms/components?fields=DEFAULT&currentPage=0&pageSize=16&componentIds=SeqirusResourceProduct1%2CSeqirusResourceProduct2%2CSeqirusResourceProduct3"); 
     return this.http.get(url);
  }
  getAccess1(){
    const url = this.occ.buildUrl("/cms/components/SeqirusResourceProduct1"); 
     return this.http.get(url);
  }
  getAccess2(){
    const url = this.occ.buildUrl("/cms/components/SeqirusResourceProduct2"); 
     return this.http.get(url);
  }
  getAccess3(){
    const url = this.occ.buildUrl("/cms/components/SeqirusResourceProduct3"); 
     return this.http.get(url);
  }
  getBannerNode(){
    const url = this.occ.buildUrl("/cms/components/HomepageUserGuidePDFComponent"); 
    return this.http.get(url);
  }

  getSearchAccount(params:HttpParams):Observable<any>{
    return this.http.get(this.occ.buildUrl("/users/current/join-account/searchAccount"),{params:params,responseType:'text'});
  }

  getRequestAssistance(params: HttpParams): Observable<any> {
    //console.log(params);
    return this.http.get(this.occ.buildUrl("/users/current/join-account/requestAssistance"),{params:params,responseType:'text',observe:'response'});
  
  }
  submitJoinAccount(data: any): Observable<any> {
    return this.http.post(this.occ.buildUrl("/users/current/join-account/submit"),data);
  
  }
  
  getLeftContentForCreateAccount(){
    const url = this.occ.buildUrl("/cms/components/RegistrationBanner"); 
     return this.http.get(url);
  }
  vsscontent(){
    const url = this.occ.buildUrl("/users/current/my-account/vss?fields=FULL"); 
    return this.http.get(url);
  }
  verifyRecaptcha(captchaResp:string) {
    
    const headers = new HttpHeaders({
      'Accept':'application/json'
    })
    return this.http.get(this.occ.buildUrl(`/users/current/captcha-check/${captchaResp}`),{headers});
  }
  savedCreditDetail(){
    const url = this.occ.buildUrl("/users/current/financials/fetch-saved-cards?fields=DEFAULT");
    return this.http.get(url);
  }
}
