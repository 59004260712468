<div class="col-lg-5 col-md-5 col-sm-12 open-order float_left" *ngIf= "this.firstvalue != 'N/A'">

    <div *ngIf="rightisLoading" class="loading">
        <div class="loading_txt">
        <div class="load_txt">Loading</div>
            <span></span>
            <span></span>
            <span></span>
            <span></span>
        </div>
    </div>
    <div class="form-group">
        <div class="col-md-8 col-lg-8 col-sm-8 no-padding for_tab_view float_left">
            <div class="input-group seach_order">
                <div class="input-group-addon">
                    <i class="fa fa-search"></i>
                </div>
                <input (keyup)="inputsearch($event);applyFilter()" class="form-control" id="open_order_search" name="lookup" type="text" placeholder="Search, filter, or sort your orders">
            </div>
        </div>
                     <div class="col-md-1 col-2 filterbox float_left"  (click)="filteropen()" style="background-image: url('../assets/images/filter.png');" >
            <span class="select_counter">{{count}}</span>
        </div>
        <div class="col-md-1 col-2 sortbox float_left" (click)="sortopen()" style="background-image: url('../assets/images/sort.png');" [ngClass]="togglesortView?'open':''"></div>
    
         
        <div class="filteropen" [ngClass]="togglefilterView?'open':''">
          
            <div class="statussection">
                <div class="filterheader">BY STATUS</div>
                <div class="row">
                    <div class="col-md-12"  *ngFor="let list of getstatus;let i=index">
                        <label> <input type="checkbox" class="registration_checkbox" name="status" value={{list}} (click)="incrementcountstatus($event,i);applyFilter()" > <span class="checkbox-button__control" ></span>{{list}}</label>
                    </div>
                    
                </div>
                
              
            </div>
        </div>
        <div class="sortopen" [ngClass]="togglesortView?'open':''">
            <div class="row">
                <div class="col-md-6">
                    <div class="filterheader">INVOICE NUMBER</div>
                    <label> <input type="radio" class="registration_radio" name="radiobtn" value="1to9" (click)="sortTest('1to9')"> <span class="radio-button__control"></span>1 to 9</label> <label> <input type="radio" class="registration_radio" name="radiobtn" value="9to1" (click)="sortTest('9to1')"> <span class="radio-button__control"></span>9 to 1</label>
                </div>
                <div class="col-md-6">
                    <div class="filterheader">LOCATION</div>
                    <label> <input type="radio" class="registration_radio" name="radiobtn" value="AtoZ" (click)="sortTest('AtoZ')"> <span class="radio-button__control"></span>A to Z</label> <label> <input type="radio" class="registration_radio" name="radiobtn" value="ZtoA" (click)="sortTest('ZtoA')"> <span class="radio-button__control"></span>Z to A</label>
    
                </div>
            </div>
            <br>
            <div class="row">
                <div class="col-md-6">
                    <div class="filterheader">DATE ISSUED</div>
                    <label> <input type="radio" class="registration_radio" name="radiobtn" value="MostRecent" (click)="sortTest('MostRecent')"> <span class="radio-button__control"></span>MOST RECENT</label> <label> <input type="radio" class="registration_radio" name="radiobtn" value="LeastRecent" (click)="sortTest('LeastRecent')"> <span class="radio-button__control"></span>LEAST RECENT</label>
    
    
                </div>
            </div>
        </div>
        
    </div>
    
    <div class="clearfix"></div>
    
    <hr>
    <div *ngIf="isLoading">loading</div>
     <div *ngIf="!isLoading">
   
    <div class="mat-elevation-z8 " id="orderfiltertable">
        <mat-form-field class="mattablesearch">
          <mat-label>Filter</mat-label>
          <input matInput (keyup)="inputsearch($event);applyFilter()" placeholder="Search, filter, or sort your orders">
        </mat-form-field>
        <table mat-table [dataSource]="dataSource" matSort class="mat-elevation-z8">
        
    
     <ng-container matColumnDef="combined" >
 <th class="odertableheader" mat-header-cell *matHeaderCellDef> order Data </th>
      <td mat-cell *matCellDef="let element" (click)="openrightsectio(element.invoiceNumber,element.status)"> 
        <div class="tablecolum_order1">        
        <div class="order_id">Invoice #{{element.invoiceNumber}}</div>
        <div class="order_unit"><span>Unit Qty Total :</span><span class="totalorderquantity_Order" [className]=element.invoiceNumber>{{element.invoiceQuantity}}</span></div>
        <div class="order_location"><span>Shipping Location:</span><span [innerHtml]="element.location"></span></div>
        </div>
        
         <div class="tablecolumn_order2"><div class="invoice_status {{element.status | lowercase}}">{{element.status}}</div></div>
        </td>
          
 
      </ng-container>
      
          <!-- Header and Row Declarations -->
          <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
          <tr mat-row id="{{row.invoiceNumber}}" *matRowDef="let row; columns: displayedColumns;"></tr>
        </table>
<!--        <mat-paginator #paginator  [pageSizeOptions]="[5, 10, 20]" showFirstLastButtons></mat-paginator>
 -->    
   <div class="custom-paginator">
    <button class="pagination-buttonOrg" (click)="goToFirstPage()" [disabled]="isFirstPage()">
      <img alt="Navigate to previous page" src="assets/images/pagination-double-prev-arrow-icon.svg" class="locations_arrows">
    </button>
    <button class="pagination-buttonOrg" (click)="previousPage()" [disabled]="isFirstPage()">
      <img alt="Navigate to previous page" src="assets/images/pagination-prev-btn.svg" class="locations_arrows">
    </button>
    <button class="pagination-buttonOrg" *ngFor="let pge of getPages()" [class.active-page]="isCurrentPage(pge)" (click)="goToPage(pge)">
      {{pge}}
    </button>
    <button class="pagination-buttonOrg" (click)="nextPage()" [disabled]="isLastPage()">
      <img alt="Navigate to next page" src="assets/images/pagination-next-btn.svg" class="locations_arrows">
    </button>
    <button class="pagination-buttonOrg" (click)="goToLastPage()" [disabled]="isLastPage()">
      <img alt="Navigate to last page" src="assets/images/pagination-double-next-arrow-icon.svg" class="locations_arrows">
    </button>
    </div>  
 </div>
    </div>
    </div>


    <!-- right section for my orders -->
    
      
    <div class="col-lg-7 col-md-7 col-span-12 right-order  order-details-view float_left" *ngIf="this.firstvalue != 'N/A'">
      
      <ng-container *ngIf="rightsectionresponse">
        <div class="order_details_section col-md-12" id="{{rightsectionresponse.invoiceNumber}}">
    
            
                <div class="row invoice_topHeader">
                    <div>
                        <div class="invoice_no">Invoice Number #<span class="invoice_fetch_id">{{rightsectionresponse.invoiceNumber}}</span>
                        </div>
                        
                    </div>
                    <div>                        
                        <div class="invoice_download invoice_download-US"><img alt="Download" class="invoice_download-svg" src="assets/images/icon-download-invoice.svg" width="15" (click)="downloadInvoice(rightsectionresponse.invoiceNumber)"></div>
                            
                        </div>
                        <div class="invoice_paybutton newClass" *ngIf= "rightsectionresponse.invoiceStatus !== 'PAID'">
                            <div *ngIf="rightsectionresponse.showPaymentProccessingBanner == false">
                                <div class="invoice_pay invoice_paycreditcard" (click)="ShowCredit()" [ngClass]="currentDivCredit || showCardSection || showSuccessOrError?'open':''" >Pay by Credit Card</div>
                                    <div class="invoice_pay invoice_payACH" (click)="ShowAch()"  [ngClass]="currentDivAch?'open':''" >Pay by ACH</div>
                                </div>
                                <div class="clearfix"></div> 
                                <div class="moveRight" *ngIf="rightsectionresponse.showPaymentProccessingBanner == true">      
                                                                 
                                     <div class="invoice_pay invoice_payACH" (click)="ShowAch()" [ngClass]="currentDivAch?'open':''">Pay by ACH</div>
                                    </div>
                                <div class="clearfix"></div>
                                <!--pay by credit card 1-->
                                <div class="invoice_paypopup invoice_payCareditpopup" *ngIf="currentDivCredit && rightsectionresponse.total < 150000">
                                    <form id="pay_creditcard" novalidate="novalidate">
                                       <div class="order_panel_header">
                                            
                                               <div class="row">
                                                 <div class="grey_sec col-xs-12"> 
                                                   <div class="col-md-3 col-xs-4">
                                                       <div class="order_level">invoice total</div>
                                                       
                                                       <div class="fetch_data">{{rightsectionresponse.total | number : '1.2-2'}}</div>
                                                   </div>
                                                   <div class="col-md-5 col-xs-8">
                                                       <div class="order_level">amount due (if paid by {{rightsectionresponse.paymentDueDate}})</div>
                                                       <div class="fetch_data fetch_data_actual">${{rightsectionresponse.amountDue | number : '1.2-2'}}</div>
                                                   </div>
                                                   <div class="col-md-4 col-xs-7 hidden-xs hidden-sm visible-md-* visible-lg-*">
                                                              <div class="order_level">po number</div>
                                                         <div class=""><input type="text" name="card_po" class="invoice_edit_fld" id="card_po" autocomplete="no"></div>
                                                   </div>
                                                   <div class="col-md-8 col-xs-12 margin-T10">  <!-- save credit card changes -->
                                                                    <div class="order_level">Total</div>
                                                                    <div class="fetch_data_total">$<span>{{rightsectionresponse.total| number : '1.2-2'}}</span><span *ngIf="rightsectionresponse.discountApplicable"  class="static_data">&nbsp;({{rightsectionresponse.payDiscount | number}}% Prompt Pay Discount)</span></div>
                                                                    
                                                                    <div class="invoice_creditnote-list" *ngIf="substractCredit" >
                                                                        <div class="invoice_insidelist" *ngFor="let item of creditOnToal; let i=index" (click)="makeRemovetoggle()">
                                                                        <div class="invoice_creditnote-item" id="invoice_creditnote-{{rightsectionresponse.invoiceNumber}}">- <span class="invoice_creditamountApplied">${{item.creditAmount}}</span> Credit Note # <span class="invoice_creditID">{{rightsectionresponse.invoiceNumber}}</span> 
                                                                            <span (click)="creditApplied($event,item.creditAmount,'remove',item.creditNoteNumber, item.id)" class="invoice-creditremoveButton">REMOVE <i class="glyphicon glyphicon-minus invoiceCreditIcon"></i></span></div></div>
                                                                        <hr>
                                                                        <div class="invoice_creditnote-sum" [ngClass]="(getFinalCreditAmmount() < 0)? 'error':'' " >
                                                                            <span class="invoice_creditnote-sumAmt" *ngIf="isNegative == true"> - ${{getFinalCreditAmmount()* -1 | number : '1.2-2'}} </span>
                                                                            <span class="invoice_creditnote-sumAmt" *ngIf="isNegative == false"> ${{getFinalCreditAmmount() | number : '1.2-2'}} </span>
                                                                            &nbsp;Credit Card Payment<span class="invoice_creditnote-sumError" > 
                                                                                 <img src="assets/images/exclamation-mark.png" height="20" *ngIf="getFinalCreditAmmount() < 0" matTooltip="For assistance applying credits to this invoice please contact the flu360 Customer Service team at (855)-358-8966. Our hours of operation are Monday through Friday, 9:00 AM to 6:30 PM EDT/EST.">
                                                                                <!-- <span class="invoice_creditnote-tooltip">For assistance applying credits to this invoice please contact the flu360 Customer Service team at (855)-358-8966. Our hours of operation are Monday through Friday, 9:00 AM to 6:30 PM EDT/EST.</span> -->
                                                                            </span>                                                                                                                   
                                                                        </div>
                                                                        <div *ngIf="getFinalCreditAmmount() < 0">
                                                                        <label  class="invoice_errorLabel">Your applied credits exceed the total amount due on the invoice.</label>
                                                                    </div>
                                                                    </div>
                                                                    <!------apply credit changes end------>

                                                                </div>
                                                  <div class="col-md-4 col-xs-12"> <!------ apply credit mobile design ------>
                                                    <div class="order_level">&nbsp;</div>
                                                    <div class="static_data hidden-xs hidden-sm"> <!-- apply credit mobile design -->
                                                    {{payMessage.paymentLimitMessage}}</div>
                                                </div>
                                               </div>
                                              
                                           </div>
                                           <div class="row">
                                            <div (click)="hideCredit()" class="col-md-6 col-xs-6 padding-L15"><span class="cancel_btn_edit">Cancel</span></div> <!-- save credit card changes -->
                                            <div  [ngClass]="disableNext?'disabled':'' "  (click)="showCards()" class="col-md-6 col-xs-6 padding-R15"><span  type="button" class="save_btn_edit">Next</span></div> <!-- save credit card changes -->
                                           </div>
                                       </div>	
                                       </form>
                                       <div class="invoice_applyCredit-parent" *ngIf="finalCreditList && finalCreditList.length == 0">
                                        <div class="invoice_applyCredit-header">Apply Credits</div>
                                        <div class="invoice_applyCredit-amount">Total available credits:&nbsp;<span class="zeroapplycredit">${{rightsectionresponse.totalcreditamount |number : '1.2-2'}}</span></div>
                                        <!-- <div class="invoice_applyCredit-text availableCreditText hidden">The total amount of credits applied must not exceed the total amount due on the invoice.<br><br>It may take several business days for a credit to be removed from your Available Credits if the credit was applied to an invoice outside of flu360.com.</div> -->
                                        
                                        <div class="invoice_applyCredit-text noAvailableCredit">There are currently no available credits. Once credits become available, they will be listed.</div> <!-- to be shown if no credit available-->
                                        <!-- to be shown if credit available-->
                                        </div>
                                        <div class="invoice_applyCredit-parent" *ngIf="finalCreditList && finalCreditList.length > 0">
                                            <div class="invoice_applyCredit-header">Apply Credits</div>
                                            <div class="invoice_applyCredit-amount">Total available credits:&nbsp;<span>${{rightsectionresponse.totalcreditamount |number : '1.2-2' }}</span></div>
                                            <div class="invoice_applyCredit-text availableCreditText">The total amount of credits applied must not exceed the total amount due on the invoice.<br><br>It may take several business days for a credit to be removed from your Available Credits if the credit was applied to an invoice outside of flu360.com.</div>
                                            
                                            
                                        </div>
                                            <div class="invoice_applycreditTable" *ngIf="finalCreditList.length> 0"> <!-- to be shown if credit available-->
<!--                                               
                                              <mat-form-field  class="full-width">
                                                
                                                <mat-label>seach,filter, or sort your orders</mat-label>
                                                <input
                                                  matInput
                                                  (keyup)="applyFilterNew($event)"
                                                  
                                                  #input
                                                />
                                              </mat-form-field> -->
                                              
                                              <div class="input-group-table seach_order">
                                                <!-- <mat-label>seach,filter, or sort your orders</mat-label> -->
                                                <div class="input-group-addon-table">
                                                    <i class="fa fa-search"></i>
                                                </div>
                                                <input (keyup)="applyFilterNew($event)" class="form-control" id="open_order_search-table" name="lookup" type="text" >
                                            </div>
                                              <table class="tableClass display dataTable no-footer" mat-table [dataSource]="dataSource2" class="mat-elevation-z8">
  
                                                <ng-container matColumnDef="billTo">
                                                    <th class="heading" mat-header-cell *matHeaderCellDef> Billing Address </th>
                                                    <td mat-cell class="matRow" *matCellDef="let element"> {{element.billTo.billingAddress}} </td>
                                                </ng-container>
                                              
                                               
                                                <ng-container matColumnDef="invoiceDate">
                                                  <th class="invoiceDate" mat-header-cell *matHeaderCellDef> Date Issued </th>
                                                  <td mat-cell class="matRow" *matCellDef="let element">{{element.invoiceDate}} </td>
                                                </ng-container>
                                              
                                                <ng-container matColumnDef="invoiceNumber">
                                                  <th class="invoiceNumber" mat-header-cell *matHeaderCellDef>Credit Note#</th>
                                                  <td mat-cell class="matRow" *matCellDef="let element">{{element.invoiceNumber}} </td>
                                                </ng-container>
                                                <ng-container matColumnDef="formattedAmountWithTax">
                                                    <th class="formattedAmountWithTax" mat-header-cell *matHeaderCellDef>Credit Amount</th>
                                                    <td mat-cell class="matRow" *matCellDef="let element">{{element.formattedAmountWithTax}} </td>
                                                  </ng-container>
                                                  <ng-container matColumnDef="status">
                                                    <th class="status" mat-header-cell *matHeaderCellDef></th>
                                                    <!-- <td mat-cell class="invoice_applybutton" *matCellDef="let element">{{element.formattedAmountWithTax}} </td> -->
                                                    <td mat-cell class=" matRow invoice_applybutton" *matCellDef="let element;let i = index" >
                                                        <span id="appliedId-{{i}}" class="buttonStyles applyCreditClass" (click)="creditApplied($event,element.amoutWithTax,'credit',element.invoiceNumber, i)">APPLY <i class="glyphicon glyphicon-plus invoiceCreditIcon"></i></span>
                                                        <span id="removeId-{{i}}" class="buttonStyles  removeCreditClass" (click)="creditApplied($event,element.amoutWithTax,'remove',element.invoiceNumber,i)">REMOVE <i class="glyphicon glyphicon-minus invoiceCreditIcon"></i></span>

                                                    </td>
                                                  </ng-container>
                                                <tr mat-header-row *matHeaderRowDef="displayedColumns2"></tr>
                                                <tr mat-row *matRowDef="let row; columns: displayedColumns2;"></tr>
                                             
                                            </table>
                                       </div>
                                   </div>
                                   <div class=" invoice_paypopup invoice_AccountReceivable" *ngIf="rightsectionresponse.total >= 150000 && currentDivCredit">
                                    <div class="invoice_AccountReceivable-text" [innerHTML]="payMessage.partialPaymentMessage"></div>
                                       <button class="invoice_AccountReceivableClose" (click)="hideCredit()">Close</button> 
                                       </div>
                                    <div class="invoice_paypopup invoice_payACHpopup" *ngIf="currentDivAch"  [innerHtml]="payMessage.achMessage">
                                        <!-- <div class="invoice_paypopuptext">Contact your bank or financial institution with the following information to pay your invoice by ACH. Need help? Contact us and we'll walk you through it.</div>
                                        <div class="invoice_paypopuptextheader">For electronic fund transfers (ACH/Wire)</div>
                                        <div class="invoice_paypopuptext">CSL Seqirus USA Inc.<br>Bank of America<br>ACH - ABA# 111000012<br>Wire - ABA# 026009593<br>Account# 4451449931<br></div>
                                        <div class="invoice_paypopuptextheader">Send check payments to (USPS Only):</div>
                                        <div class="invoice_paypopuptext">CSL Seqirus USA Inc/Bank of America Lockbox<br>P.O. Box 745986<br>Atlanta, GA 30374-5986<br></div>
                                        <div class="invoice_paypopuptextheader">Send check payments to (Courier service only/UPS/FedEx):</div>
                                        <div class="invoice_paypopuptext">CSL Seqirus USA Inc/Bank of America Lockbox<br>Lockbox 745986<br>6000 Feldwood Road<br>College Park, GA 30349<br></div>
                                        <div class="invoice_paypopupbutton"><a href="/contact-us" target="_blank">Find Support</a></div> -->
                                  
                                   </div>
                                   <div class="invoice_paypopup select-payment" *ngIf="showCardSection">
                                                                                    
                                    <div class="select-payment_header" >
                                        <div class="select-payment_header-text ">Select payment method</div>
                                        <div class="select-payment_secure "><img alt="" width="11" src="assets/images/lock-key.png">Secure payment</div>
                                    </div>
                                    <div class="select-payment_savedCards" >
                                    <div class="select-payment_savedCardsHeader">Saved credit cards</div>
                                        <div *ngIf="savedCardsData && savedCardsData.length>0" id="select-payment_savedCards-table_wrapper" class="dataTables_wrapper no-footer">
                                            <div *ngFor="let item of savedCardsData ">
                                            <table class="select-payment_savedCards-table dataTable no-footer" id="select-payment_savedCards-table" role="grid" >
                                        <thead>
                                            
                                            <tr role="row"><th class="sorting_disabled" rowspan="1" colspan="1"></th></tr>
                                        </thead>
                                        <tbody>
                                            <tr role="row" class="odd"><td><div class="select-payment-parent" id="item.cardNumber" (click)="savedCardClicked(item)">
                                                <img class="select-payment-cardImage" alt="" width="39" src="assets/images/Discover_icon.png">
                                            <div class="select-payment-cardno">{{item.cardNumber}}<div class="select-payment-expiryDate">Expires {{item.expiryDate}}</div></div>
                                            <div class="select-payment-arrow"> <i class="fa fa-chevron-right"></i></div></div></td></tr></tbody>
                                        </table>
                                    </div>
                                </div>
                                        <div class="select-payment_nosavedCards"  *ngIf="savedCardsData && savedCardsData.length == 0">You don't have any saved credit cards. You can save a credit card during the payment process or in My Profile.</div>
                                        </div>
                                    

                                    <div class="select-payment_savedCardsHeader">New credit cards<i class="fa fa-question-circle-o payment-info-tooltip">
                                            <span class="payment-info_Cardsheader-tooltiptext invoicenewcredit-tooltip">New credit cards or debit cards may be subject to a temporary authorization hold from your bank. No action will be needed from you - holds typically disappear in 1-3 business days.</span>
                                        </i>
                                    </div>

                                    <div class="select-payment_addCards " (click)="iframClicked()">
                                        <div class="select-paymentAdd-parent">
                                            <img class="select-paymentAdd-cardImage" alt="" width="39" src="assets/images/amex_icon.png">
                                            <div class="select-paymentAdd-cardName">American Express</div>
                                            <div class="select-paymentAdd-arrow"><i class="fa fa-chevron-right"></i></div>
                                        </div>
                                        <div class="select-paymentAdd-parent">
                                            <img class="select-paymentAdd-cardImage" alt="" width="39" src="assets/images/Visa_icon.png">
                                            <div class="select-paymentAdd-cardName">Visa</div>
                                            <div class="select-paymentAdd-arrow"><i class="fa fa-chevron-right"></i></div>
                                        </div>
                                         <div class="select-paymentAdd-parent">
                                            <img class="select-paymentAdd-cardImage" alt="" width="39" src="assets/images/master_icon.png">
                                            <div class="select-paymentAdd-cardName">MasterCard</div>
                                            <div class="select-paymentAdd-arrow"><i class="fa fa-chevron-right"></i></div>
                                        </div>
                                        <div class="select-paymentAdd-parent">
                                            <img class="select-paymentAdd-cardImage" alt="" width="39" src="assets/images/Discover_icon.png">
                                            <div class="select-paymentAdd-cardName">Discover</div>
                                            <div class="select-paymentAdd-arrow"><i class="fa fa-chevron-right"></i></div>
                                        </div>
                                        
                                    </div>
                                </div>
                                <div class="invoice_paypopup paymentfailed mobpaddingpayment" *ngIf="showSuccessOrError && errorCode==3">
                                    <div class="order_panel_header successcontainer">
                                    <div class="row">
                                            <div class="col-md-12">
                                                <div class="title_data">{{payErrorMsg3.errorHeadline}}</div>
                                            </div>
                                
                                        </div>
                                        <div class="row">
                                            <div class="col-md-12">
                                                <div class="msg_data">
                                                    {{payErrorMsg3.h2content}}</div>
                                                <div class="msg_data paymentfailtext">{{payErrorMsg3.content}}</div>
                                
                                            </div>
                                
                                        </div>
                                        <div class="row">
                                            
                                            <div class="failerbtncont col-xs-12" id="failurebtncont">
                                                <div class="failerretry mobretry" id="retry" (click)="retryClicked()">Retry or Change Method</div>
                                                <div class="failercancelbtn" id="cancel" (click)="cancelTransactionClicked()">Cancel Transaction</div>
                                            </div>
                                        </div>
                                        </div>
                                </div>
                                <div class="invoice_paypopup paymentfailed mobpaddingpayment" *ngIf="showSuccessOrError && errorCode==2">
                                    <div class="order_panel_header successcontainer">
                                    <div class="row">
                                            <div class="col-md-12">
                                                <div class="title_data">{{payErrorMsg2.errorHeadline}}</div>
                                            </div>
                                
                                        </div>
                                        <div class="row">
                                            <div class="col-md-12">
                                                <div class="msg_data">
                                                    {{payErrorMsg2.h2content}}</div>
                                                <div class="msg_data paymentfailtext">{{payErrorMsg2.content}}</div>
                                
                                            </div>
                                
                                        </div>
                                        <div class="row">
                                            
                                            <div class="failerbtncont col-xs-12" id="failurebtncont">
                                                <div class="failerretry mobretry" id="retry" (click)="retryClicked()">Retry or Change Method</div>
                                                <div class="failercancelbtn" id="cancel" (click)="cancelTransactionClicked()">Cancel Transaction</div>
                                            </div>
                                        </div>
                                        </div>
                                </div>
                                <div class="invoice_paypopup paymentfailed mobpaddingpayment" *ngIf="showSuccessOrError && errorCode==1">
                                    <div class="order_panel_header successcontainer">
                                    <div class="row">
                                            <div class="col-md-12">
                                                <div class="title_data">{{payErrorMsg1.errorHeadline}}</div>
                                            </div>
                                
                                        </div>
                                        <div class="row">
                                            <div class="col-md-12">
                                                <div class="msg_data">
                                                    {{payErrorMsg1.h2content}}</div>
                                                <div class="msg_data paymentfailtext">{{payErrorMsg1.content}}</div>
                                
                                            </div>
                                
                                        </div>
                                        <div class="row">
                                            
                                            <div class="failerbtncont col-xs-12" id="failurebtncont">
                                                <div class="failerretry mobretry" id="retry" (click)="retryClicked()">Retry or Change Method</div>
                                                <div class="failercancelbtn" id="cancel" (click)="cancelTransactionClicked()">Cancel Transaction</div>
                                            </div>
                                        </div>
                                        </div>
                                </div>              
                                <!-- this.iframredirect  -->
                                <div class="invoice_paypopup" id="iframePopup" *ngIf="iframredirect" >
                                    
                                <iframe width="100%" height="400" frameborder="0" [src]="cleanURL()">
                                    
                                </iframe>
                                </div>
                    </div>
                </div>
                
                <div class="col-md-12 payment_status_update" *ngIf="showSuccessBanner || rightsectionresponse.showPaymentProccessingBanner">
                   {{payMessage.paymentProcessingMessage}}</div>
                    <div class="row invoice_topHeader">
                        <div class="col-md-2 invoice_duedate">
                            <label>DUE ON</label>
                            <div> {{rightsectionresponse.paymentDueDate | dateformat}}</div>                                            
                        </div>
                        <div class="col-md-2 invoice_issueddate">
                            <label>ISSUED ON</label>
                            <div>{{rightsectionresponse.invoiceDate}}</div>  
                        </div>
                        <div class="col-md-4 invoice_payment">
                            <label>PAYMENT</label>
                            <div>{{rightsectionresponse.paymentTermDescription}}</div>                                            
                        </div>
                        <div class="col-md-3 invoice_totalcost">
                            <label>TOTAL COST</label>
                            
                            <div><span>$ {{rightsectionresponse.total | number : '1.2-2'}}</span> USD</div>  
                        </div>
                        <div class="col-md-1 invoice_detailstatusParent">
                            <div class="invoice_detailstatus {{rightsectionresponse.invoiceStatus | lowercase}}">{{rightsectionresponse.invoiceStatus}}</div> 
                            <div class="clearfix"></div>
                        </div>
                        <div class="clearfix"></div> 
                    </div>
                
                <div class="invoice_topHeader">
                    <div class="invoice_detailbox1">
                        <div class="invoice_boxdetail invoice_detailPO">
                            <div class="invoice_detaillabel invoice_detailPOlabel">PO NUMBER</div>
                            <div class="invoice_detailno invoice_detailPOno">{{rightsectionresponse.poNumber}}</div>
                            <div class="invoice_detailissued invoice_detailPOissued">Issued on: <span>{{rightsectionresponse.poDate}}</span></div>
                            
                             </div>
                        <div class="invoice_boxdetail invoice_detailorder">
                            <div class="invoice_detaillabel invoice_detailorderlabel">ORDER NUMBER</div>
                            <div class="invoice_detailno invoice_detailorderno">#{{rightsectionresponse.salesOrderNumber}}</div>
                               <div class="invoice_detailissued invoice_detailorderissued">Placed on: <span>{{rightsectionresponse.placedOn}}</span></div>
                            <div class="invoice_detailotherorder" style="display: none;">View other invoices for this order<span><img alt="Black view next arrow"  width="20"></span></div>
                        </div>
                    </div>
                    <div class="invoice_detailbox2">
                        <div class="invoice_boxdetail invoice_detaildelivery">
                            <div class="invoice_detaillabel invoice_detaildeliverylabel">DELIVERY ORDER NUMBER</div>
                            <div class="invoice_detailno invoice_detaildeliveryno">{{rightsectionresponse.deliveryNumber}}</div>                                     
                        </div>
                    </div>
                    <div class="invoice_detailbox3">
                        <div class="invoice_boxdetail invoice_detailship">
                            <div class="invoice_detaillabel invoice_detailshiplabel">SHIP TO</div>
                            <div class="invoice_detailno invoice_detailshipno">#{{rightsectionresponse.shipTo.shipToId}}</div>
                            <div class="invoice_detaillabel invoice_detailshiploc">Location Name</div>
                            <div class="invoice_detailissued invoice_detailshipaddress">{{rightsectionresponse.shipTo.shipToStreet}},&nbsp;{{rightsectionresponse.shipTo.shipToCity}},&nbsp;{{rightsectionresponse.shipTo.shipToState}},&nbsp;{{rightsectionresponse.shipTo.shipToZipCode}}</div>
                        </div>
                        <div class="invoice_boxdetail invoice_detailbil">
                            <div class="invoice_detaillabel invoice_detailbilllabel">BILL TO</div>                                        
                            <div class="invoice_detailno invoice_detailbillno">#{{rightsectionresponse.billTo.billToId}}</div>   
                            <div class="invoice_detaillabel invoice_detailbillloc">Location Name</div>
                            <div class="invoice_detailissued invoice_detailbilladdress">{{rightsectionresponse.billTo.billToStreet}},&nbsp;{{rightsectionresponse.shipTo.shipToCity}},&nbsp;{{rightsectionresponse.billTo.billToState}}
                            ,&nbsp;{{rightsectionresponse.billTo.billToZipCode}}</div>
                        </div>  
                        
                   
                    </div>
                    <div class="invoice_detailtotalcal invoice_detailsubtotal">
                        <div class="invoice_detailtotalcallabel">Subtotal</div>  
                        <div class="invoice_detailtotalcalLine"></div>
                        
                  
                        <div class="invoice_detailtotalcalamount invoice_detailsubtotalAmount">${{rightsectionresponse.subTotal | number : '1.2-2'}}</div>
                    </div>
                    <div class="invoice_detailtotalcal invoice_detailtax">
                        <div class="invoice_detailtotalcallabel">Taxes &amp; Fees</div>  
                        <div class="invoice_detailtotalcalLine"></div>
                         
                        <div class="invoice_detailtotalcalamount invoice_detailtaxAmount">${{rightsectionresponse.vat | number : '1.2-2'}}</div>
                    </div>
                    <!-- IREC Invoice Details changes start-->
                     <div class="invoice_detailtotalcal"> 
                        <div class="invoice_detailtotalcallabel ">Total Cost</div>
                        <div class="invoice_detailtotalcalLine"></div>
                        <div class="invoice_detailtotalcalamount">${{rightsectionresponse.total| number : '1.2-2'}}</div>
                        <div class="clearfix"></div> 
                    </div>
                    <div class="invoice_detailtotalcost">
                        <div class="invoice_detailtotalcostlabel">Remaining Balance</div>
                        <div class="invoice_detailtotalcostamount">${{rightsectionresponse.amountDue| number : '1.2-2'}}</div>
                        <div class="clearfix"></div> 
                    </div>
                         <!-- IREC Invoice Details changes ends-->
                </div>
                <div class="invoice_topHeader invoice_topHeader_pdf" id="invoicePdf_23-24" style="">
                    <div class="invoice_detailpdf"><a href="/terms-conditions-sales-2023-2024" target="_blank">See Terms and Conditions of Sale</a></div>
                </div>
               
               
    



            
        </div>
    </ng-container>
    </div>
