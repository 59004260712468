<div *ngIf="seasonData | async as item">

    <div class="col-12" id="orders_topcontent">
      <div class="row">
      <div class="col-9 col-md-3 col-lg-3 right_border" id="orders_topcontentleftcontent">
        <h1 class="col-xs-12 orders_titeltop modified-h1">Invoices</h1>
      </div>
      <div class="col-3  col-md-4 col-lg-5  no-dis" id="orders_topcontentleftcontent">
        <div class="col-xs-12 orders_titeltop orders_titeltopNo" >{{getCount()}}</div>
      </div>
    
      <div class="col-12 col-md-5 col-lg-4" id="orders_topcontentleftcontent">
        
        
        <div class="row" >
          <select  (change)="selectedSeason($event)" class="current_sesson_dropdown" name="session" id="select_season">
           
          <option *ngFor="let obj of item.seqirusSeasonList; let i= index" [value]="obj" > {{(obj == item.inSeason)?'Current Season': 'Previous Season'}} : {{obj}} </option>
  
                                </select>
          
                              </div>
        </div>
      </div>
    </div>
      </div>
    